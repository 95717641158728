import {defaultEnvironment, Environment} from './default-environment';

export const environment: Environment = {
  ...defaultEnvironment,
  name: 'dev',
  url: {
    aws: 'https://52ustdc9ke.execute-api.eu-central-1.amazonaws.com/dev/app',
    flowTwin: 'https://d.flowsic-applicationdesign.endress.com/'
  },
  initializeSentry: true
};
